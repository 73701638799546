import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

import styles from './HalfContent.module.scss'

const HalfContent = ({ backgroundColor, title, side, text, image }) => {
  const classList = {
    mobileImage: [styles.mobileImage, styles[side]].join(' '),
    halfContent: [styles.halfContent, styles[side]].join(' '),
  }

  return (
    <>
      {image && (
        <Image fluid={image} className={classList.mobileImage} alt={title} />
      )}

      <div className={classList.halfContent} style={{ backgroundColor }}>
        <div className="wrapper-m">
          <div className={styles.text}>
            <h1 className="t-heading-l">{title}</h1>
            {text}
          </div>

          <div className={styles.spacer} />
        </div>
      </div>
    </>
  )
}

HalfContent.propTypes = {
  backgroundColor: PropTypes.string,
  image: PropTypes.any,
  side: PropTypes.oneOf(['left', 'right']),
  text: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
}

export default HalfContent
