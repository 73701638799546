import React from 'react'
import rehypeReact from 'rehype-react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import MdLink from '$components/utility/MdLink'
import Meta from '$components/common/Meta'
import HalfContent from '$components/common/HalfContent'

// prettier-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: MdLink,
    h2: (props) => <h2 {...props} className="t-heading-l">{props.children}</h2>,
    h3: (props) => <h3 {...props} className="t-heading-m">{props.children}</h3>,
  }
}).Compiler

export default ({ data: { content } }) => (
  <BackgroundImage
    critical
    className="contentBackgroundImage"
    fluid={content.frontmatter.background.childImageSharp.fluid}
    style={{ height: '100%' }}
  >
    <Meta
      title={content.frontmatter.title}
      description={content.frontmatter.description}
    />

    <HalfContent
      image={content.frontmatter.background.childImageSharp.fluid}
      side={content.frontmatter.side}
      text={renderAst(content.htmlAst)}
      title={content.frontmatter.title}
      backgroundColor={content.frontmatter.backgroundColor}
    />
  </BackgroundImage>
)

export const query = graphql`
  query Half($locale: String!, $title: String!) {
    content: markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      htmlAst
      frontmatter {
        title
        description
        side
        backgroundColor
        background {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
